import './App.css';

function App() {
  return (
    <div>
      <body>
        <div className="content">
          <div id="grid">
            <img src="img/avatar-rossi.png" id="avatar-rossi" />
            <div className="text">
              <h2>Rodrigo Rossi</h2>
              <p>
              Videomaker especializado em clipes e propagandas, dirigiu mais de 500 projetos ao longo de uma década no audiovisual.</p>
<p>
Fez trabalhos para empresas como Forbes e Latam, e gravou artistas dos mais variados estilos, tais como Ivete Sangalo, Gabriel o Pensador, Maneva, Lauana Prado, Fabio Brazza, Angra, Malta, Matanza, MC Pedrinho, MC Dede e Fat Family, totalizando mais de 300 milhões de acessos na internet.
</p><p>
Atualmente produz conteúdo próprio com foco em entretenimento no Tiktok e Youtube, somando mais de 2 milhões de seguidores e ultrapassando 2 milhões de visualizações por dia.
              </p>
            </div>

            <div class="social">
              <a rel="noreferrer" target="_blank" href="https://www.instagram.com/rossi.rodrigo" class="social-icon"><img src="img/instagram.png" /></a>
              <a rel="noreferrer" target="_blank" href="https://vm.tiktok.com/TWSxhb/" class="social-icon"><img src="img/tiktok.png" /></a>
              <a rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/UCwWItAkSn_vlj-T62MwBnrg" class="social-icon"><img src="img/youtube.png" /></a>
              <a rel="noreferrer" target="_blank" href="https://facebook.com/canaldorossi" class="social-icon"><img src="img/facebook.png" /></a> 
            </div>

            <div class="buttons-grid">
            <a target="_blank" href="Rodrigo Rossi - Mídia Kit 2021.pdf">
            <div class="button">
              BAIXAR MÍDIA KIT
            </div>
          </a>
            </div>

            {/* <a class="contato">
              <img src="img/icon-whatsapp.png" /><span>+55 11 93757-6774</span>
            </a> */}
            <a class="contato" href="mailto:comercial@rossirodrigo.com">
              <img src="img/icon-email.png" /><span>comercial@rossirodrigo.com</span>
            </a>
          </div>
        </div>
      </body>
    </div>
  );
}

export default App;
